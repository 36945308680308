import React from "react";
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

// Core
import Layout from "@landingpage/core/src/components/Layout";
import Topbar from "@landingpage/core/src/components/Topbar";
import Footer from "@landingpage/core/src/components/Footer";
import SEO from "@landingpage/core/src/components/SEO";
import RegistrationSmokeTestForm from "@landingpage/core/src/components/Registration/RegistrationSmokeTestForm";
import LanguageMapper from "@landingpage/core/src/utils/LanguageMapper";

const RegistrationSmokeTestPage = ({ data, location }) => {
  const page = data.prismicRegistrationSmoketest;

  if (!page) return null;
  return (
    <Layout>
      <SEO
        lang={page.lang}
        title={RichText.asText(page.data?.seo_title?.raw)}
        description={RichText.asText(page.data?.seo_description?.raw)}
      />
      <Topbar
        topbar={page.data?.topbar?.document?.data}
        logo={{ width: 230, height: 40 }}
      />
      <RegistrationSmokeTestForm
        pageData={page.data}
        lang={LanguageMapper(page.lang)}
        location={location}
      />
      <Footer
        year={page.last_publication_date}
        footer={page.data?.footer?.document?.data}
        logo={{ width: 150, height: 50 }}
      />
    </Layout>
  );
};

export const query = graphql`
  query RegistrationSmoketestById($id: String!) {
    prismicRegistrationSmoketest(id: { eq: $id }) {
      id
      lang
      last_publication_date(formatString: "Y")

      data {
        topbar {
          document {
            ...TopbarFragment
          }
        }
        footer {
          document {
            ...FooterFragment
          }
        }
        seo_title {
          raw
        }
        seo_description {
          raw
        }
        step_1_title {
          raw
        }
        step_1_description {
          raw
        }
        first_name_placeholder {
          raw
        }
        emailadres_placeholder {
          raw
        }
        emailadres_reminder {
          raw
        }
        registration_success_title {
          raw
        }
        registration_success_text {
          raw
        }
        registration_background {
          fluid {
            ...GatsbyPrismicImageFluid
          }
          thumbnails {
            desktop {
              fluid(maxWidth: 1920) {
                ...GatsbyPrismicImageFluid
              }
            }
            tablet {
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            mobile {
              fluid(maxWidth: 768) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
export default withPreview(RegistrationSmokeTestPage);
